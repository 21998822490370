const debounce = (fn, wait) => {
  let timeout = null
  return (...args) => {
    const next = () => fn(...args)
    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}

document.addEventListener('turbolinks:load', () => {
  Array.from(document.querySelectorAll('[data-autocomplete')).forEach(input => {
    let selectedIndex = 0
    let results = []
    let term = input.value
    let searching = false
    let valueSelected = false

    const url = input.dataset.autocomplete
    const resultsElement = document.createElement('DIV')
    resultsElement.classList.add('bg-white')
    resultsElement.classList.add('border')
    resultsElement.classList.add('rounded')
    resultsElement.classList.add('py-2')
    resultsElement.classList.add('d-none')

    resultsElement.style.marginTop = '1px'
    resultsElement.style.position = 'absolute'
    resultsElement.style.left = 0
    resultsElement.style.right = 0
    resultsElement.style.zIndex = 100
    resultsElement.style.maxHeight = '240px'
    resultsElement.style.overflow = 'auto'
    input.parentElement.style.position = 'relative'

    input.parentElement.insertBefore(resultsElement, input.nextSibling)

    const performSearch = async () => {
      const response = await fetch(`${url}?term=${term}`)
      results = await response.json()
      searching = false
      render()
    }

    const debouncedPerformSearch = debounce(performSearch, 300)

    const render = () => {
      if (term.trim() === '' || valueSelected === input.value) {
        resultsElement.classList.add('d-none')
        return
      }

      if (searching) {
        resultsElement.classList.remove('d-none')
        resultsElement.innerHTML = '<div class="px-3">Searching...</div>'
      } else if (results.length === 0) {
        resultsElement.innerHTML = '<div class="px-3 text-body-secondary fst-italic">No results</div>'
      } else {
        while (resultsElement.firstChild) {
          resultsElement.removeChild(resultsElement.firstChild)
        }

        results.forEach((result, idx) => {
          const resultElement = document.createElement('DIV')
          resultElement.classList.add('js-result')
          resultElement.classList.add('py-2')
          resultElement.classList.add('px-3')
          resultElement.classList.add('cursor-pointer')

          resultElement.addEventListener('click', () => {
            selectIndex(idx)
          })

          resultElement.addEventListener('mouseenter', (e) => {
            selectedIndex = idx

            const oldSelected = resultElement.parentElement.querySelector('.bg-primary')
            oldSelected.classList.remove('bg-primary')
            oldSelected.classList.remove('text-white')
            resultElement.classList.add('bg-primary')
            resultElement.classList.add('text-white')
          })

          if (idx !== 0) {
            resultElement.classList.add('border-top')
          }

          if (idx === selectedIndex) {
            resultElement.classList.add('bg-primary')
            resultElement.classList.add('text-white')
          }

          const nameElement = document.createElement('DIV')
          nameElement.classList.add('fw-bold')

          nameElement.innerText = result.label ? result.label : result.value
          resultElement.appendChild(nameElement)

          if (result.label && result.label !== result.value) {
            const valueElement = document.createElement('DIV')
            valueElement.classList.add('opacity-75')
            valueElement.innerText = result.value
            resultElement.appendChild(valueElement)
          }

          resultsElement.appendChild(resultElement)
        })

        const currentElement = resultsElement.querySelector(`.js-result:nth-child(${selectedIndex + 1})`)
        currentElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }

      resultsElement.classList.remove('d-none')
    }

    const selectIndex = (index) => {
      if (results.length > 0 && !searching) {
        input.value = results[index].value
        valueSelected = input.value
        term = input.value
        resultsElement.classList.add('d-none')
      }
    }

    input.addEventListener('keyup', (e) => {
      if (term !== input.value) {
        term = input.value
        searching = true
        selectedIndex = 0
        valueSelected = false
        render()
        debouncedPerformSearch()
      }
    })

    input.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault()
          if (selectedIndex > 0) {
            selectedIndex--
            render()
          }
          break
        case 'ArrowDown':
          e.preventDefault()
          if (selectedIndex < results.length - 1) {
            selectedIndex++
            render()
          }
          break
        case 'Enter':
          if (term !== valueSelected && results.length > 0) {
            e.preventDefault()
          }
          selectIndex(selectedIndex)
          break
        case 'Escape':
          resultsElement.classList.add('d-none')
          results = []
          break
      }
    })

    render()
  })
})
