import { Tooltip, Popover } from 'bootstrap'

const plugins = {
  tooltip: Tooltip,
  popover: Popover
}

const selector = Object.keys(plugins).map(key => `[data-bs-toggle=${key}]`).join(', ')

function initializeBootstrap (container = document) {
  container.querySelectorAll(selector).forEach(el => {
    const Plugin = plugins[el.dataset.bsToggle]
    if (Plugin) new Plugin(el)
  })
}

//  Initialize on page load
document.addEventListener('DOMContentLoaded', () => initializeBootstrap())

// Observe DOM for new elements and initialize them
const observer = new MutationObserver(mutationsList => {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      [...mutation.addedNodes].forEach(addedNode => {
        if (addedNode instanceof HTMLElement) {
          initializeBootstrap(addedNode)
        }
      })
    }
  }
})

observer.observe(document, { childList: true, subtree: true })
