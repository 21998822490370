import './application.scss'

import '../lib/analytics'
import '../lib/autocomplete'
import '../lib/clipboard'
import '../lib/toggle'
import '../lib/dismiss'
import '../lib/value-sync'
import '../lib/scroll'
import '../lib/active-link'
import '../lib/toc'
import '../lib/docsearch'
import '../lib/time_zone'
import '../lib/bootstrap'
import "chartkick/chart.js"
import Turbolinks from 'turbolinks'
import Rails from '@rails/ujs'

Turbolinks.start()
Rails.start()
