const scrollToHash = (hash) => {
  const element = document.querySelector(`${hash},[name="${hash.replace('#', '')}"]`)
  if (element) {
    element.parentElement.scrollIntoView(true)
  }
}

document.addEventListener('turbolinks:load', () => {
  Array.from(document.querySelectorAll('a[href^="#"]')).forEach(link => {
    link.addEventListener('click', () => {
      scrollToHash(link.getAttribute('href'))
    })
  })

  if (document.location.hash !== '') {
    scrollToHash(document.location.hash)
  }
})
