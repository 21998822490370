// Mark nav links active as sections scroll into view
class TableOfContents {
  constructor (links) {
    this.links = Array.from(links)
    this.observer = new IntersectionObserver(this.handler.bind(this))
  }

  // Observe sections for all nav links
  observe () {
    this.links.forEach(link => {
      const target = document.querySelector(`${link.hash}, *[name="${link.hash.slice(1)}"`)
      if (target) this.observer.observe(target)
    })
  }

  // Called by IntersectionObserver when elements enter or leave viewport
  handler (entries) {
    entries.forEach(entry => {
      const hash = '#' + (entry.target.getAttribute('id') || entry.target.getAttribute('name'))
      const link = this.links.find(link => link.hash === hash)

      if (entry.isIntersecting) {
        link.classList.add('visible')
      } else {
        link.classList.remove('visible')
      }

      const firstVisible = this.findLink('.visible')
      if (firstVisible) {
        this.findLink('.active')?.classList.remove('active')
        firstVisible.classList.add('active')
      }
    })
  }

  findLink (selector) {
    return this.links.find(link => link.matches(selector))
  }
}

window.addEventListener('turbolinks:load', () => {
  new TableOfContents(document.querySelectorAll('nav a[href^="#"]')).observe()
})
