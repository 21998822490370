const syncValues = (e) => {
  const target = e.target
  if (target.dataset.valueSync) {
    const value = target.value
    const elementToUpdate = document.getElementById(target.dataset.valueSync)
    elementToUpdate.value = value
  }
}

document.addEventListener('change', syncValues)
document.addEventListener('input', syncValues)
