import docsearch from '@docsearch/js'

document.addEventListener('turbolinks:load', () => {
  const container = document.querySelector('#docsearch')
  if (!container) return

  docsearch({
    appId: '9YH80SPD1F',
    apiKey: '19263cd05cc040faddea9cb80ada16ce',
    indexName: 'flippercloud-docs',
    container,
    debug: false // Set debug to true if you want to inspect the modal
  })
})
