import ClipboardJS from 'clipboard'
import { Popover } from 'bootstrap'

document.addEventListener('turbolinks:load', () => {
  const clipboard = new ClipboardJS('.js-clipboard-button')

  clipboard.on('success', function (e) {
    const popover = new Popover(e.trigger)
    popover.show()
    setTimeout(popover.hide, 2000)

    if (document.selection) {
      document.selection.empty()
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges()
    }
  })

  clipboard.on('error', function (e) {
    console.error('error', e)
  })
})
